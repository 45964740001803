
import { Component, Vue, Prop } from "vue-property-decorator";
import { IFile } from "@/models/IPropertyAccounting";
import { HousfyFlapV2, DocumentsRow } from "housfy-ui-lib";

@Component({
  components: {
    HousfyFlapV2,
    DocumentsRow,
  },
})
export default class InvoicesFlap extends Vue {
  @Prop({ type: Array, default: () => [] })
  invoices!: IFile[];

  @Prop({ type: Boolean, default: true })
  viewUrl!: boolean;

  @Prop({ type: Boolean, default: false })
  onlyDownload!: boolean;

  openFile(url: string): void {
    if (!this.viewUrl) return;
    window.open(url);
  }

  openFileDownload(url: string): void {
    window.open(url);
  }
}
